import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [{
        path: '*',
        redirect: '/'
    },
    {
        name: 'index',
        path: '/',
        component: () =>
            import ('./view/index/index'),
        meta: {
            title: '未莱掌柜宝-收款'
        }
    },
    {
        name: 'register',
        path: '/register',
        component: () =>
            import ('./view/index/register'),
        meta: {
            title: '未莱掌柜宝-会员激活'
        }
    },
    {
        name: 'addOil',
        path: '/addOil',
        component: () =>
            import ('./view/pay/addOil'),
        meta: {
            title: '未莱掌柜宝-加油付款'
        }
    },
    {
        name: 'payConfirm',
        path: '/payConfirm',
        component: () =>
            import ('./view/pay/payConfirm'),
        meta: {
            title: '未莱掌柜宝-支付确认'
        }
    },
    {
        name: 'paySuccess',
        path: '/paySuccess',
        component: () =>
            import ('./view/pay/paySuccess'),
        meta: {
            title: '未莱掌柜宝-支付成功'
        }
    },
    {
        name: 'showPayPage',
        path: '/showPayPage',
        component: () =>
            import ('./view/pay/newPay'),
        meta: {
            title: '未莱掌柜宝'
        }
    },
    {
        name: 'activationSuccess',
        path: '/activationSuccess',
        component: () =>
            import ('./view/index/activationSuccess'),
        meta: {
            title: '未莱掌柜宝-激活成功'
        }
    },
    {
        name: 'payFail',
        path: '/payFail',
        component: () =>
            import ('./view/pay/payFail'),
        meta: {
            title: '未莱掌柜宝-支付失败'
        }
    },
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {
    router
};