import Vue from 'vue';
import App from './App';
import { router } from './router';
import VueAppend from 'vue-append';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard)
Vue.use(VueAppend);
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);


/*  http请求封装 */
import http from "./util/http"
Vue.prototype.$get = http.get;
Vue.prototype.$post = http.post;
Vue.prototype.$json = http.json;
Vue.prototype.$download = http.download;



new Vue({
    router,
    el: '#app',
    render: h => h(App)
});