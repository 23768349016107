/* 
	 axios 请求工具
	已做好错误的处理
 */
    import axios from "axios"
    import { Toast } from 'vant';
    var baseUrl = "";
    //测试环境
    if (process.env.NODE_ENV === 'development') {
        // baseUrl = "http://lot.qhdgwdzkj.com/tom";
        // baseUrl="https://wlzgb.dongkebao.com/api"

        baseUrl = "/api";
    }
    if (process.env.NODE_ENV === 'production') {
        // baseUrl="https://wlzgb.dongkebao.com/api";
        baseUrl="https://wlzgb.dongkebao.com/api"

    }
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 6000,
    });
    // cookie
    instance.defaults.withCredentials = true
    // 请求携带token
    instance.interceptors.request.use(
        config => {
            config.headers = {
                "b": sessionStorage.getItem("token"),
                "a": sessionStorage.getItem("username")
            }
            return config
        }
    )
    // 响应拦截器
    instance.interceptors.response.use(
        res => {
            // 服务器有响应
            var status = res.status;
            if (status == 200) {
                var obj = res.data;
                if (obj.code == 201) {
                    window.location = "/"
                }
                if (obj.code == 400) {
                    Toast.success(obj.message);
                }
                if (obj.code == 500) {
                    Toast.fail(obj.message)
                }
                return res;
            }
        },
        err => {
            // Toast.fail(obj.message);
            return Promise.reject(err);
        }
    )
    
    /* get请求 */
    function get(url, data) {
        return new Promise((resolve, reject) => {
            instance({
                url: url,
                method: "GET",
                params: data
            }).then(res => {
                resolve(res.data)
            }).catch(res => {
                reject(res)
            })
        })
    }
    /** post请求 **/
    function post(url, data) {
        return new Promise((resolve, reject) => {
            instance({
                url: url,
                method: "POST",
                params: data
            }).then(res => {
                resolve(res.data)
            }).catch(res => {
                reject(res)
            })
        })
    }
    /** post请求 提交 json **/
    function json(url, data) {
        return new Promise((resolve, reject) => {
            instance({
                url: url,
                method: "POST",
                data: data
            }).then(res => {
                resolve(res.data)
            }).catch(res => {
                reject(res)
            })
        })
    }
    
    /** 下载 **/
    function download(url, data) {
        return new Promise((success, error) => {
            instance({
                url: url,
                method: "GET",
                params: data,
                responseType: 'blob'
            }).then(res => {
                success(res.data)
                if (res.status == 200) {
                    // 从返回的headers中拿到文件名称
                    let filename = res.headers['content-disposition'].split(';')[1].split('=')[1];
                    filename = escape(filename)
                    filename = decodeURI(filename)
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                }
            }).catch(res => {
                error(res)
            })
        })
    }
    export default {
        baseUrl,
        get,
        post,
        json,
        download,
    }
    